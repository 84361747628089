@font-face {
	font-family: "Finador Light";
	src: url("./fonts/Finador-Light.ttf");
}
  
@font-face {
	font-family: "Futura Lt BT Light";
	src: url("./fonts/futura-light-bt.ttf");
}

@font-face {
  font-family: "Futura-Bold";
  src: url("./fonts/Futura-Bold-03.ttf");
}
  
  @font-face {
    font-family: "Grotesk-ExtraBold";
    src: url("./fonts/CabinetGrotesk-Extrabold.otf");
  }

  @font-face {
    font-family: "Lucida-Grande";
    src: url("./fonts/LucidaGrande.ttc");
  }

  @font-face {
    font-family: "Helvetica-Neue";
    src: url("./fonts/HelveticaNeue.ttc");
  }

  @font-face {
	font-family: "Futura Medium";
	src: url("./fonts/Futura-Medium-01.ttf");
  }
  
  @font-face {
	font-family: "Futura Bk BT";
	src: url("./fonts/FutuBk.ttf");
  }

  @font-face {
	font-family: "Roboto-Regular";
	src: url("./fonts/Roboto-Regular.ttf");
  }

  @font-face {
    font-family: "Roboto-Black";
    src: url("./fonts/Roboto-Black.ttf");
  }
  
  @font-face {
    font-family: "Roboto-Medium";
    src: url("./fonts/Roboto-Medium.ttf");
  }

  @font-face {
	font-family: "Lato-Bold";
	src: url("./fonts/Lato-Bold.ttf");
  }

  @font-face {
    font-family: "Lato-Black";
    src: url("./fonts/Lato-Black.ttf");
    }
  @font-face {
	font-family: "Lato-Heavy";
	src: url("./fonts/Lato-Heavy.ttf");
  }
  @font-face {
	font-family: "Lato-Medium";
	src: url("./fonts/Lato-Medium.ttf");
  }
  @font-face {
  font-family: "Jost-Book";
  src: url("./fonts/Jost-400-Book.otf") format("opentype");
}
@font-face {
    font-family: "Jost-Medium";
    src: url("./fonts/Jost-500-Medium.otf") format("opentype");
}

  body{
	margin: 0;
  }