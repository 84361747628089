  

  .pdfLoader {
	display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  
  .pdfbuttonContainer {
	height: 100px;
	border-bottom: 1px solid black;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	background-color: white;
	z-index: 999;
  }

  .pdfbuttonContainerCL {
	height: 100px;
	border-bottom: 1px solid black;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: fixed;
	max-width: 210mm;
	background-color: white;
	z-index: 999;
  }
  
  .pdfprintPDFButton {
	border: none;
	font-size: 24px;
	background-color: rgb(225, 8, 28);
	border-radius: 30px;
	padding: 10px;
	cursor: pointer;
	color: white;
  }
  
  .pdfcontainer {
	position: relative;
	width: 1123px;
	height: 793px;
  }

  .pdfcontainerCL {
	position: relative;
	width: 793px;
	height: 1123px;
  }

  .pdfcontainerPortrait {
	position: relative;
	width: 793px;
	height: 1123px;
	top: 100px;
	display: flex;
  }
  
  
  .pdfcontainerBorder {
	border-bottom: 1px solid black;
  }
  
  
  .pdfplusProduit {
	font-family: "Futura Medium";
	font-size: 13px;
  }
  
  .pdfplusProduit > ul {
	margin: 0;
	padding: 0;
  }
  
  h1 {
	font-size: 26px;
  }
  
  h2 {
	font-size: 12px;
	font-family: "Futura Lt BT Light";
  }
  
  h2 > span {
	font-family: "Futura Medium";
  }
  
  h3 > span {
	font-family: "Futura Medium";
  }
  
  h3 {
	font-size: 10px;
	font-family: "Futura Bk BT";
	margin: 0;
  }
  
  table,
  th,
  td {
	border: 1px solid rgb(240, 240, 240);
	border-collapse: collapse;
  }
  
  p {
	margin: 0;
  }
  
  tr {
	height: 20px;
  }

  