  
  .homewrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
  }
  
  .homecontainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
  }
  
  .hometitle {
	font-family: "Finador Light";
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
  }
  
  .homebuttonsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .blueButton {
	font-family: "Futura Lt BT Light";
	font-size: 20px;
	color: white;
	border: 0;
	background-color: #002654;
	padding: 20px;
	border-radius: 50px;
	margin-bottom: 50px;
	cursor: pointer;
  }
  
  .redButton {
	font-family: "Futura Lt BT Light";
	font-size: 20px;
	color: white;
	border: 0;
	background-color: #c8102e;
	padding: 20px;
	border-radius: 50px;
	margin-bottom: 50px;
	cursor: pointer;
  }
  
  .homePageButtonsPdf {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }

  .homePageButtonsLabel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 50px;
	align-items: center;
  }